// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ArtworkDetailLayout from '@templates/layouts/ArtworkDetailLayout'

interface Props {
  data: Queries.ArtworkDetailKnownArtistQuery
  pageContext: PageProps & pageContextProps
}

const ArtworkDetailUnknownArtist = ({ data, pageContext }: Props) => {
  return (
    <ArtworkDetailLayout
      unknownArtistPageData={data}
      activeNavSlug={pageContext.activeNavSlug}
    />
  )
}

export const query = graphql`
  query ArtworkDetailUnknownArtist($originalId: String!) {
    datoCmsArtwork(originalId: { eq: $originalId }) {
      ...artworkDetail
    }
    moreArtwork: allDatoCmsArtwork(
      limit: 8
      filter: {
        availability: { originalId: { eq: "61095150" } }
        originalId: { ne: $originalId }
      }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      edges {
        node {
          ...artworkCard
        }
      }
    }
  }
`

export default ArtworkDetailUnknownArtist
